import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import Img from "gatsby-image/withIEPolyfill"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Display,
  Header,
  AboutNav,
  AboutBox,
  Markdown,
  AboutBar,
  NewsImg,
  NewsText,
  NewsWrapper,
} from "../styles"

const abouts = {
  education: "Education",
  artistic_prizes: "Artistic Prizes",
  fellowships_and_academic_awards: "Fellowships and Academic Awards",
  residencies_festivals: "Residencies / Festivals",
  invited_talks_lectures: "Invited Talks",
  teaching: "Teaching",
  collaborations: "Collaborations",
  discography: "Discography",
}

const About = ({ data }) => {
  const about = data.strapiAbout

  return (
    <Layout>
      <SEO title="About" />
      <Display center>About</Display>

      <NewsWrapper row justifyEvenly alignStart>
        <NewsText>
          <Markdown>
            <ReactMarkdown source={about.biography} escapeHtml={false} />
          </Markdown>
        </NewsText>

        <NewsImg>
          <Img
            style={{ borderRadius: "5px", marginTop: "20px" }}
            fluid={about.picture.childImageSharp.fluid}
          />
        </NewsImg>
      </NewsWrapper>

      <AboutBar row wrap justifyEvenly>
        {Object.entries(abouts).map(list => (
          <AnchorLink key={list[0]} to={`/about#${list[0]}`} stripHash>
            <AboutNav>{list[1]}</AboutNav>
          </AnchorLink>
        ))}
      </AboutBar>

      {Object.entries(abouts).map(list => (
        <AboutBox key={list[0]} id={list[0]}>
          <Header>{list[1]}</Header>
          <Markdown>
            <ReactMarkdown source={about[list[0]]} escapeHtml={false} />
          </Markdown>
        </AboutBox>
      ))}
    </Layout>
  )
}

export default About

export const aboutQuery = graphql`
  query {
    strapiAbout {
      biography
      education
      artistic_prizes
      fellowships_and_academic_awards
      residencies_festivals
      invited_talks_lectures
      teaching
      collaborations
      discography
      picture {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
